import React, { useContext } from "react"
import { graphql } from "gatsby"
import { Box, Flex } from "@singita/components"
import ConditionalLayout from "../components/ConditionalLayout"
import RichTextRenderer from "../components/RichTextRenderer"
import { Meta, Heading, ActionBar, Text, Button } from "@singita/components"
import SocialSharing from "../components/SocialSharing"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import { useActionBar, useSeo } from "gatsby-singita-theme/src/hooks"
import { PLAN_YOUR_TRIP } from "../modules/Availability/common/constants"
import AvailabilityContext from "gatsby-singita-theme/src/context/AvailabilityContext"
import { Translation } from "react-i18next"
import { LocaleLink } from "gatsby-singita-theme/src/components/LocaleLink"

const DATA_MAP = {
  title: ["headline"],
  description: ["highlight"],
  image: ["featuredImage", "fluid", "src"],
  altText: ["highlight"],
}

const PromotionTemplate = props => {
  const {
    data: {
      contentfulPromotions: { headline, content, region },
    },
    pageContext,
  } = props
  const seoProps = useSeo(props.data.contentfulPromotions, DATA_MAP)
  const jsonLd = getJsonLd({ ...seoProps }, TEMPLATE_TYPES.PROMOTION)

  const [actionbarRef, isMobile] = useActionBar()
  const { setShowAvailability } = useContext(AvailabilityContext)

  const isForeignLanguage =
    pageContext && pageContext.locale && pageContext.locale !== "en-US"

  return (
    <>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />
      <ConditionalLayout modalSize="medium" pageContext={props.pageContext}>
        {isModal => (
          <Box
            maxWidth={isModal ? "none" : "700px"}
            mx="auto"
            pt={isModal ? [0] : [10]}
            pb={isModal ? [0] : [14]}
          >
            <Box mb={[3]}>
              <Meta
                meta={
                  region
                    ? [{ text: region[0].country, color: "brandBrown" }]
                    : []
                }
              />
            </Box>
            <Box mb={[3]}>
              <Heading size="h1" as="h1">{headline}</Heading>
            </Box>
            <ActionBar
              ref={actionbarRef}
              isSticky
              headline={isMobile ? null : headline}
              description={isMobile ? null : "in Promotions"}
            >
              {!isMobile && (
                <Box mr={[2]}>
                  <Text ml={[0, 2]} size="small">
                    {/** TODO: (Localisation) localise this text */}
                    Share:
                  </Text>
                </Box>
              )}
              <Flex mr={[2]}>
                <SocialSharing
                  {...seoProps}
                  size={isMobile ? "small" : "default"}
                />
              </Flex>
              {!isForeignLanguage ? (
                <Button
                  variant="primary"
                  colorScheme="brandBrown"
                  onClick={() => setShowAvailability(true)}
                >
                  {PLAN_YOUR_TRIP.label}
                </Button>
              ) : (
                <LocaleLink to="/contact">
                  <Button variant="primary" colorScheme="brandBrown">
                    <Translation>{t => t("promotions.contact")}</Translation>
                  </Button>
                </LocaleLink>
              )}
            </ActionBar>
            {content && <RichTextRenderer content={content} />}
          </Box>
        )}
      </ConditionalLayout>
    </>
  )
}

export default PromotionTemplate

export const promotionQuery = graphql`
  query PromotionQuery($slug: String!, $locale: String) {
    contentfulPromotions(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      name
      headline
      slug
      featuredImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      content {
        raw
        references {
          ...RichTextReferences
        }
      }
      region {
        headline
        country
      }
      structuredData {
        headline
        image {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description {
          description
        }
        altText
      }
    }
  }
`
